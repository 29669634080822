import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const SimpleContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);
  return (
    <section
      className={`common-content lg:py-60 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }${
        module?.backgroundStyle === 'background with pattern'
          ? ' bg-pattern'
          : ''
      }`}
    >
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView || hasPlayed ? 'show' : 'hidden'}
        variants={container}
        className="w-[860px] m-auto text-center lgscreen:w-full px-20 fade-ani"
      >
        {module?.heading && (
          <motion.div variants={item} className="title-black">
            <h3>{module?.heading}</h3>
          </motion.div>
        )}
        {module?.description && (
          <motion.div variants={item} className="content ">
            {parse(module?.description)}
          </motion.div>
        )}
        {module?.link?.title && (
          <motion.div
            variants={item}
            className="btn-custom mt-30 relative z-9 "
          >
            <Link
              to={module.link.url || '#'}
              className={
                !module?.linkStyle || module?.linkStyle === 'link'
                  ? 'button btn-red-border'
                  : 'button header-btn-red cursor-pointer'
              }
            >
              <span>{module.link.title}</span>
            </Link>
          </motion.div>
        )}
      </motion.div>
    </section>
  );
};

export default SimpleContent;
export const DestinationSimpleContentFragment = graphql`
  fragment DestinationSimpleContentFragment on WpDestination_Destinationcontent_DestinationContent_SimpleContent {
    id
    hideSection
    extraClass
    backgroundStyle
    heading
    description
    link {
      target
      title
      url
    }
  }
`;
export const ExperienceListingSimpleContentFragment = graphql`
  fragment ExperienceListingSimpleContentFragment on WpPage_Pagecontent_PageContent_SimpleContent {
    id
    hideSection
    extraClass
    backgroundStyle
    heading
    description
    link {
      target
      title
      url
    }
    linkStyle
  }
`;
export const ExperienceSingleSimpleContentFragment = graphql`
  fragment ExperienceSingleSimpleContentFragment on WpExperience_Experiencecontent_Experience_SimpleContent {
    id
    hideSection
    extraClass
    backgroundStyle
    heading
    description
    link {
      target
      title
      url
    }
    linkStyle
  }
`;
export const TravelStyleSingleSimpleContentFragment = graphql`
  fragment TravelStyleSingleSimpleContentFragment on WpTravel_Travelstylecontent_TravelStyle_SimpleContent {
    id
    hideSection
    extraClass
    backgroundStyle
    heading
    description
    link {
      target
      title
      url
    }
    linkStyle
  }
`;
