import Banner from '../components/Banner';
import GuestFeedbacks from '../components/GuestFeedbacks';
import ImageWithContent from '../components/ImageWithContent';
import NavigationContent from '../components/NavigationContent';
import RelatedBlog from '../components/RelatedBlog';
import RelatedTripInspirationList from '../components/RelatedTripInspirationList';
import SimpleContent from '../components/SimpleContent';
import SimpleFullImageWithContent from '../components/SimpleFullImageWithContent';
import SimpleFullImageWithModalContent from '../components/SimpleFullImageWithModalContent';
import StaySliderWithModalContent from '../components/StaySliderWithModalContent';
import BestTimeForGo from '../components/BestTimeForGo';
import { graphql } from 'gatsby';
import React from 'react';

const Destination = ({ modules, location }) => {
  const components = {
    Banner,
    NavigationContent,
    SimpleContent,
    SimpleFullImageWithContent,
    RelatedTripInspirationList,
    StaySliderWithModalContent,
    GuestFeedbacks,
    SimpleFullImageWithModalContent,
    ImageWithContent,
    RelatedBlog,
    BestTimeForGo,
  };

  return (
    <>
      {modules
        ? modules
            .filter(
              (module) => !module?.hideSection || module?.hideSection === 'no'
            )
            .map((module, i) => {
              const moduleName = module.__typename.replace(
                'WpDestination_Destinationcontent_DestinationContent_',
                ''
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  location,
                })
              );
            })
        : null}
    </>
  );
};

export default Destination;
export const DestinationModulesFragment = graphql`
  fragment DestinationModulesFragment on WpDestination {
    destinationContent {
      destinationContent {
        __typename
        ...SimpleFullImageWithModalContentFragment
        ...DestinationBannerFragment
        ...NavigationContentFragment
        ...DestinationSimpleContentFragment
        ...DestinationSimpleFullImageWithContentFragment
        ...DestinationRelatedTripInspirationListFragment
        ...StaySliderWithModalContentFragment
        ...DestinationGuestFeedbacksFragment
        ...DestinationImageWithContentFragment
        ...DestinationRelatedBlogFragment
        ...DestinationBestTimeForGoFragment
      }
    }
  }
`;
