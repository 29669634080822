import ImageOpt from './ImageOpt';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const RelatedTripInspirationList = ({ module }) => {
  const ref = useRef(null);
  const ref2 = useRef(null);

  const isInView = useInView(ref);
  const isInView2 = useInView(ref2);

  const [hasPlayed, setHasPlayed] = useState(false);
  const [hasPlayed2, setHasPlayed2] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  const container2 = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.9,
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    if (isInView2 && !hasPlayed2) {
      setHasPlayed2(true);
    }
  }, [isInView2, hasPlayed2, setHasPlayed2]);

  const pageSize = module?.postPerPage || 10;
  const [numOfItemsToDisplay, setNumOfItemsToDisplay] = useState(pageSize);

  const onLoadMore = () => {
    setNumOfItemsToDisplay(numOfItemsToDisplay + pageSize);
  };

  const makeBold = (item) => {
    let keyword = item;
    if (
      item === 'South Africa' ||
      item === 'Namibia' ||
      item === 'Botswana' ||
      item === 'Mozambique' ||
      item === 'Madagascar' ||
      item === 'Mauritius' ||
      item === 'Seychelles' ||
      item === 'Uganda' ||
      item === 'Tanzania' ||
      item === 'Rwanda' ||
      item === 'Kenya' ||
      item === 'Republic of Congo' ||
      item === 'Zambia' ||
      item === 'Zimbabwe'
    ) {
      const re = new RegExp(keyword, 'g');
      const boldItem = item
        .replace(
          re,
          "<span className='font-800 text-red-300'>" + keyword + '</span>'
        )
        .toString();

      return boldItem;
    } else {
      const re = new RegExp(keyword, 'g');
      const normalItem = item
        .replace(re, '<span>' + keyword + '</span>')
        .toString();
      return normalItem;
    }
  };

  return (
    <section
      className={`bx-img-content bg-pattern py-60${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid-xl">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="section-title text-center fade-ani"
        >
          {module?.heading && (
            <motion.div variants={item} className="title-black">
              <h3>{module.heading}</h3>
            </motion.div>
          )}
          {module?.description && (
            <motion.div variants={item} className="content ">
              {parse(module.description)}
            </motion.div>
          )}
        </motion.div>
        <motion.div
          ref={ref2}
          initial="hidden"
          animate={isInView2 || hasPlayed2 ? 'show' : 'hidden'}
          variants={container}
          className="bx-img-content-main grid gap-y-14 mdscreen:gap-y-7 lg:mt-60 mt-30"
        >
          {module?.trip?.length > 0 &&
            module.trip.slice(0, numOfItemsToDisplay).map((trip, index) => (
              <motion.div variants={item}>
                <Link to={trip?.uri} key={uuidv4()}>
                  <div className="bx-img-content-grid fade-ani" key={trip.id}>
                    <div className="flex flex-wrap">
                      <div className="lg:w-5/12 w-full">
                        <ImageOpt
                          imgData={trip?.featuredImage?.node.mediaItemUrl}
                          imgAlt={trip?.featuredImage?.node?.altText}
                          imgClass="w-full"
                          width={475}
                          height={287}
                        />
                      </div>
                      <div className="lg:w-7/12 w-full">
                        <div className="trip-content bg-white lg:px-80 px-15 h-full flex flex-col justify-center lg:p-0 p-30">
                          <div className="lg:w-[400px] !w-full">
                            <h6>
                              {trip?.tripContent?.meta?.totalNights}
                              <span> {trip?.tripContent?.meta?.price}</span>
                            </h6>
                            <div className="title-black mt-10 mb-5 ">
                              <h5>{trip.title}</h5>
                            </div>
                            <div className="content flex mt-5 ">
                              <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                className="text-red-300 fa-map-marker-alt mt-[6px] pr-[8px]"
                              />
                              <div className="inline">
                                {trip.tripContent?.meta?.location?.map(
                                  (item, i) => (
                                    <span className="dest-item" key={i}>
                                      {parse(makeBold(item.title))}
                                    </span>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="btn-custom flex flex-wrap items-start justify-start space-x-8 mt-20 ">
                              <span className="button btn-red-border">
                                Explore This Trip
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}

          {module?.trip?.length > 0 &&
            module?.trip?.length > numOfItemsToDisplay && (
              <div className="btn-custom flex flex-wrap items-center justify-center space-x-8 mt-30 lgscreen:mt-15 fade-ani">
                <button className="button btn-border" onClick={onLoadMore}>
                  <span>More Trip Inspiration</span>
                  <img
                    src="/images/btn-red-bg.png"
                    loading="eager"
                    alt="Load More Button"
                  />
                </button>
              </div>
            )}
        </motion.div>
      </div>
    </section>
  );
};

export default RelatedTripInspirationList;
export const NavigationContent_RelatedTripInspirationListFragment = graphql`
  fragment NavigationContent_RelatedTripInspirationListFragment on WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_RelatedTripInspirationList {
    id
    extraClass
    hideSection
    heading
    description
    postPerPage
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            mediaItemUrl
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
            }
            curatedBy {
              ... on WpTeam {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const DestinationRelatedTripInspirationListFragment = graphql`
  fragment DestinationRelatedTripInspirationListFragment on WpDestination_Destinationcontent_DestinationContent_RelatedTripInspirationList {
    id
    extraClass
    hideSection
    heading
    description
    postPerPage
    trip {
      ... on WpTrip {
        id
        uri
        title
        featuredImage {
          node {
            mediaItemUrl
            gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
            altText
          }
        }
        tripContent {
          meta {
            totalNights
            price
            location {
              ... on WpDestination {
                id
                title
              }
            }
            features {
              icon
              name
            }
            curatedBy {
              ... on WpTeam {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;
