import { graphql } from "gatsby";
import React, { useEffect } from "react";
import PageLayout from "../components/Layout/Page";
import Seo from "../components/Seo";
import Destination from "../modules/Destination";

const DestinationTemplate = (props) => {
  const modules = props?.data?.destination?.destinationContent?.destinationContent || [];

  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
    document.body.classList.remove("header-sticky");
    document.body.classList.remove("video-modal-open");
    document.body.classList.remove("blog-single-custom");

    
  }, []);
  
  return (
    <PageLayout>
      <Seo seo={props.data.destination.seo} />
      <Destination modules={modules} location={props.location} />
    </PageLayout>
  )
}

export default DestinationTemplate;
export const DestinationById = graphql`
  query destinationById ($id: String) {
    destination: wpDestination(id: { eq: $id }) {
      id
      ...seoDestinationFragment
      ...DestinationModulesFragment
    }
  }
`;