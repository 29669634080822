import BestTimeForGo from './BestTimeForGo';
import DestinationSlider from './DestinationSlider';
import GuestFeedbacks from './GuestFeedbacks';
import ImageWithContent from './ImageWithContent';
import OurInsights from './OurInsights';
import RelatedTripInspirationList from './RelatedTripInspirationList';
import SimpleFullImageWithContent from './SimpleFullImageWithContent';
import StaySliderWithModalContent from './StaySliderWithModalContent';
import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import {
  Link as Customlink,
  Element,
  Events,
  animateScroll as scroll,
} from 'react-scroll';

const NavigationContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delay: 0.5,
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleScroll = () => {
    if (!isInViewport(document.querySelector('#Sticky1'))) {
      document.body.classList.add('sticky-block-sticky');
    }

    if (isInViewport(document.querySelector('.banner'))) {
      document.body.classList.remove('sticky-block-sticky');
    }

    if (
      isInViewport(document.querySelector('.newsletter-content')) ||
      isInViewport(document.querySelector('.footer-navbar')) ||
      isInViewport(document.querySelector('.footer-logos')) ||
      isInViewport(document.querySelector('.copyright'))
    ) {
      document.body.classList.remove('sticky-block-sticky');
    } else if (
      !isInViewport(document.querySelector('.banner')) &&
      !isInViewport(document.querySelector('#Sticky1'))
    ) {
      document.body.classList.add('sticky-block-sticky');
    }
  };

  const isInViewport = (element) => {
    if (!element) {
      return false;
    }
    if (typeof window !== undefined) {
      const rect = element.getBoundingClientRect();
      if (!rect) {
        return false;
      }
      return rect.top < window.innerHeight - 100 && rect.bottom + 100 >= 0;
    }
    return false;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  let navigationContentComponents = {
    ImageWithContent,
    BestTimeForGo,
    RelatedTripInspirationList,
    DestinationSlider,
    OurInsights,
    GuestFeedbacks,
    SimpleFullImageWithContent,
    StaySliderWithModalContent,
  };

  return (
    <>
      <section
        className={`sticky-block${
          module?.extraClass ? ' ' + module.extraClass : ''
        }`}
        id="Sticky1"
      >
        <div className="bg-pattern">
          <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView || hasPlayed ? 'show' : 'hidden'}
            variants={container}
            className="container-fluid-xl lgscreen:px-0"
          >
            <div
              onClick={() => {
                var para = document.getElementById(
                  'mobile-sticky-block-static'
                );
                para.classList.toggle('active-sticky-block');
                var arrow = document.getElementById('active-arrow-static');
                arrow.classList.toggle('active-sticky-arrow');
              }}
              className="stick-block-mobile hidden lgscreen:block lgscreen:absolute lgscreen:right-20 lgscreen:bottom-10 lgscreen:z-9"
            >
              {/* <FontAwesomeIcon icon={faAngleDown} id='active-arrow' /> */}
              <i
                id="active-arrow-static"
                className="far fa-angle-down"
                aria-hidden="true"
              ></i>
            </div>
            <ul
              id="mobile-sticky-block-static"
              className="nav navbar-nav flex justify-center"
            >
              {module?.tabs?.length > 0 &&
                module.tabs.map((tab, index) => (
                  <li className="test li" key={index.toString()}>
                    <Customlink
                      activeClass="active"
                      className={tab?.navigationHeading}
                      to={tab?.navigationHeading}
                      spy={true}
                      smooth={true}
                      duration={500}
                      onClick={() => {
                        var para = document.getElementById(
                          'mobile-sticky-block-static'
                        );
                        para.classList.remove('active-sticky-block');
                        var arrow = document.getElementById(
                          'active-arrow-static'
                        );
                        arrow.classList.remove('active-sticky-arrow');
                      }}
                    >
                      <span>{tab?.navigationHeading}</span>
                    </Customlink>
                  </li>
                ))}
            </ul>
          </motion.div>
        </div>
      </section>

      {module.tabContent?.length > 0 &&
        module.tabContent.map((content, index) => (
          <Element
            name={content?.navigationHeading}
            className="element"
            key={index.toString()}
          >
            {content?.destinationContent?.length > 0
              ? content?.destinationContent?.map(
                  (navigationContentModule, i) => {
                    const navigationContentModuleName =
                      navigationContentModule.__typename.replace(
                        'WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_',
                        ''
                      );
                    return (
                      navigationContentComponents[
                        navigationContentModuleName
                      ] &&
                      React.createElement(
                        navigationContentComponents[
                          navigationContentModuleName
                        ],
                        {
                          key: i,
                          module: navigationContentModule,
                        }
                      )
                    );
                  }
                )
              : null}
          </Element>
        ))}
    </>
  );
};

export default NavigationContent;
export const NavigationContentFragment = graphql`
  fragment NavigationContentFragment on WpDestination_Destinationcontent_DestinationContent_NavigationContent {
    tabs {
      navigationHeading
    }
    tabContent: tabs {
      navigationHeading
      destinationContent {
        __typename
        ...NavigationContent_ImageWithContentFragment
        ...NavigationContent_BestTimeForGoFragment
        ...NavigationContent_RelatedTripInspirationListFragment
        ...NavigationContent_DestinationSliderFragment
        ...NavigationContent_OurInsightsFragment
        ...NavigationContent_GuestFeedbacksFragment
        ...NavigationContent_SimpleFullImageWithContent
        ...NavigationContent_StaySliderWithModalContent
      }
    }
  }
`;
