import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useState } from "react";
import Modal from "react-modal";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const customStylesBox = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    border: "0",
    borderRadius: "0",
    padding: "0",
    width: "100%",
    height: "100%",
  },
};

const StaySliderWithModalContent = ({ module }) => {
  const [modalIsOpenBox, setIsOpenBox] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModalBox = (content) => {
    setModalContent(content);
    document.body.classList.add("video-modal-open");
    setIsOpenBox(true);
  }
  const closeModalBox = () => {
    document.body.classList.remove("video-modal-open");
    setIsOpenBox(false);
  }
  return (
    <>
      <section className={`slider-with-popup lg:py-120 py-30${module?.extraClass ? " " + module.extraClass : ""}`}>
        <div className='container-fluid pr-0'>
          <div className='section-title mdscreen:pr-20 fade-ani'>
            {
              module?.heading && (
                <div className='title-black'>
                  <h3>{module.heading}</h3>
                </div>
              )
            }
            {
              module?.description && (
                <div className='content '>
                  {parse(module.description)}
                </div>
              )
            }

          </div>
          <div id="popupslider-slider-row" className='slider-with-popup-main relative overflow-hidden popupslider lg:mt-50 mt-25 fade-ani'>
            {
              module?.stayData?.length > 0 && (
                <Swiper
                  spaceBetween={30}
                  loop={true}
                  slidesPerView={2.5}
                  allowTouchMove={true}
                  speed={1000}
                  navigation={false}
                  modules={[Autoplay, Pagination, Scrollbar]}
                  className="slider"
                  breakpoints={{
                    100: {
                      slidesPerView: 1.3,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2.5,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3.5,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {
                    module.stayData.map((item, index) => (
                      <SwiperSlide>
                        <div className='slider-with-popup-bx hover:cursor-grab' onClick={() => openModalBox(item?.modalContent)}>
                          <GatsbyImage
                            image={getImage(item?.image)}
                            alt={item?.image?.altText || ""}
                          />
                          <div className='sp-info p-30 pb-0 mdscreen:p-15'>
                            {item?.heading && <h6 className="hover:cursor-pointer">{item.heading}</h6>}
                            {
                              item?.description && (
                                <div className='content '>
                                  {parse(item.description)}
                                </div>
                              )
                            }
                            {
                              item?.button && (
                                <div className='btn-custom flex flex-wrap items-center space-x-8 mt-20 '>
                                  <button className='flex justify-start items-center button btn-red-border hover:cursor-pointer'>
                                    {item?.button?.title}
                                  </button>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  }
                </Swiper>

              )
            }
            {/* <div className="popupslider-button-drag" id="trip-button-drag" > <span className="button-text">Drag</span></div> */}
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpenBox}
        onRequestClose={closeModalBox}
        style={customStylesBox}
      >
        <div className="zigzag-modal-popup bg-pattern2">
          <button onClick={closeModalBox} className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 inline-block z-99">
            <img src="/images/modal-close.png" loading="lazy" className='m-auto w-20 h-20' alt="Close" />
          </button>
          <div className='flex flex-wrap items-center'>
            <div className='lg:w-5/12 w-full'>
              <div className='img-slider'>
                <Swiper
                  spaceBetween={0}
                  loop={true}
                  slidesPerView={1}
                  allowTouchMove={true}
                  grabCursor={true}
                  speed={1000}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="slider mdscreen:h-[90vw]"
                >
                  {
                    modalContent?.images?.map((image, index) => (
                      <SwiperSlide key={index.toString()}>
                        <GatsbyImage 
                          image={getImage(image)}
                          alt={image?.altText || ""}
                        />
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
            <div className='lg:w-7/12 w-full'>
              <div className='zigzag-modal-popup-content lg:px-100 px-30 lg:pt-0 py-30'>
                {modalContent?.preHeading && <span className='text-red-300 uppercase text-15 mb-15 inline-block w-full fade-ani'>{modalContent?.preHeading}</span>}
                {
                  modalContent?.heading && (
                    <div className='title-black'>
                      <h3>{modalContent?.heading}</h3>
                    </div>
                  )
                }
                {
                  modalContent?.description && (
                    <div className='content global-list'>
                      {parse(modalContent.description)}
                    </div>
                  )
                }
                {
                  modalContent?.button && (
                    <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9">
                      <Link to={modalContent.button?.url} target={modalContent.button?.target} className="button header-btn-red" >
                        <span>{modalContent.button?.title}</span>
                      </Link>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StaySliderWithModalContent;
export const StaySliderWithModalContentFragment = graphql`
  fragment StaySliderWithModalContentFragment on WpDestination_Destinationcontent_DestinationContent_StaySliderWithModalContent {
    id
    extraClass
    hideSection
    heading
    description
    stayData {
      heading
      description
      button {
        target
        title
        url
      }
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 800)
      }
      modalContent {
        preHeading
        heading
        description
        button {
          target
          title
          url
        }
        images {
          altText
          gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
        }
      }
    }
  }
`;
export const NavigationContent_StaySliderWithModalContent = graphql`
  fragment NavigationContent_StaySliderWithModalContent on WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_StaySliderWithModalContent {
    id
    extraClass
    hideSection
    heading
    description
    stayData {
      heading
      description
      button {
        target
        title
        url
      }
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 800)
      }
      modalContent {
        preHeading
        heading
        description
        button {
          target
          title
          url
        }
        images {
          altText
          gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
        }
      }
    }
  }
`;