import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useState } from "react";
import Modal from "react-modal";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const customStylesBox = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#ffffff",
    border: "0",
    borderRadius: "0",
    padding: "0",
    width: "100%",
    height: "100%",
  },
};

const SimpleFullImageWithModalContent = ({ module }) => {

  const [modalIsOpenBox, setIsOpenBox] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const openModalBox = (content) => {
    setModalContent(content);
    document.body.classList.add("video-modal-open");
    setIsOpenBox(true);
  }


  const closeModalBox = () => {
    document.body.classList.remove("video-modal-open");
    setIsOpenBox(false);
  }
  return (
    <>
      <section className={`bg-img-content relative${module?.extraClass ? " " + module.extraClass : ""}`}>
        {
          module?.image && (
            <GatsbyImage
              image={getImage(module.image)}
              alt={module.image?.altText}
            />
          )
        }
        <div className={`fade-ani ${module?.contentPosition === "right" ? 'absolute bottom-70 text-right right-70 z-9 mdscreen:right-0 mdscreen:bottom-30 mdscreen:px-20' : 'absolute bottom-70 left-70 px-60 z-9 mdscreen:left-0 mdscreen:bottom-30 mdscreen:px-20'}`}>
          {
            module?.heading && (
              <div className='title-white'>
                <h2>{module.heading}</h2>
              </div>
            )
          }
          {
            module?.description && (
              <div className='content white text20 '>
                {parse(module.description)}
              </div>
            )
          }
          {
            module?.link && (
              <div className={`btn-custom mt-30 relative z-9 ${module?.contentPosition === "right" ? ' btn-custom flex flex-wrap items-start justify-end space-x-8 mt-20' : ''}`}>
                {/* <Link to={module.link?.url} className={`button ${!module?.buttonStyle || module?.buttonStyle === "filled" ? "btn-red" : "btn-transparent"}`}>
                  {module.link?.title}
                </Link> */}
                <button onClick={() => openModalBox(module?.modalContent)} className={`button ${!module?.buttonStyle || module?.buttonStyle === "filled" ? "header-btn-red" : "banner-btn-transparent"}`} >
                  {module?.link?.title}
                </button>
              </div>
            )
          }
        </div>
      </section>

      <Modal
        isOpen={modalIsOpenBox}
        onRequestClose={closeModalBox}
        style={customStylesBox}
      >
        <div className="zigzag-modal-popup bg-pattern2">
          <button onClick={closeModalBox} className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 inline-block z-99">
            <img src="/images/modal-close.png" loading="lazy" className='m-auto w-20 h-20' alt="Close" />
          </button>
          <div className='flex flex-wrap items-center'>
            <div className='lg:w-5/12 w-full'>
              <div className='img-slider'>
                <Swiper
                  spaceBetween={0}
                  loop={true}
                  slidesPerView={1}
                  allowTouchMove={true}
                  grabCursor={true}
                  speed={1000}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="slider mdscreen:h-[90vw]"
                >
                  {
                    modalContent?.modalImages?.map((image, index) => (
                      <SwiperSlide key={index.toString()}>
                        <GatsbyImage
                          image={getImage(image.image)}
                          alt={image?.image.altText || ""}
                        />
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
            <div className='lg:w-7/12 w-full'>
              <div className='zigzag-modal-popup-content lg:px-100 px-30 lg:pt-0 py-30'>
                {modalContent?.preHeading && <span className='text-red-300 uppercase text-15 mb-15 inline-block w-full fade-ani'>{modalContent?.preHeading}</span>}
                {
                  modalContent?.heading && (
                    <div className='title-black'>
                      <h3>{modalContent?.heading}</h3>
                    </div>
                  )
                }
                {
                  modalContent?.modalContent && (
                    <div className='content global-list'>
                      {parse(modalContent.modalContent)}
                    </div>
                  )
                }
                {
                  modalContent?.buttonLink && (
                    <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9">
                      <Link to={modalContent.buttonLink?.url} target={modalContent.buttonLink?.target} className="button header-btn-red" >
                        <span>{modalContent.buttonLink?.title}</span>
                      </Link>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SimpleFullImageWithModalContent;
export const SimpleFullImageWithModalContentFragment = graphql`
  fragment SimpleFullImageWithModalContentFragment on WpDestination_Destinationcontent_DestinationContent_SimpleFullImageWithModalContent {
    id
    extraClass
    hideSection
    heading
    description
    contentPosition
    link {
      target
      title
      url
    }
    buttonStyle
    image {
      altText
      gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 2048)
    }
    modalContent {
      preHeading
      heading
      buttonLink {
        target
        title
        url
      }
      modalImages {
        image {
          altText
          gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
        }
      }
      modalContent
    }
  }
`;