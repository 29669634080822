import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const BestTimeForGo = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  return (
    <section
      className={`month-content py-30 lg:py-60${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView || hasPlayed ? 'show' : 'hidden'}
        variants={container}
        className="container-fluid-xl fade-ani"
      >
        {module?.heading && (
          <motion.div variants={item} className="title-black text-center mb-30">
            <h3>{module?.heading}</h3>
          </motion.div>
        )}
        {module?.month?.length > 0 && (
          <motion.div
            variants={item}
            className="month-info flex flex-wrap justify-between lgscreen:gap-x-5 mdscreen:gap-x-[0.75rem] lgscreen:justify-center lgscreen:gap-y-5 mdscreen:gap-y-[0.75rem]"
          >
            {module.month.map((item, index) => (
              <div
                key={index.toString()}
                className={`circle-grid${
                  item.season === 'mid season' ? ' mid-season' : ''
                }${item.season === 'rainy season' ? ' rainy-season' : ''}${
                  item.season === 'peak season' ? ' peak-season' : ''
                }`}
              >
                <span>{item.name}</span>
              </div>
            ))}
          </motion.div>
        )}
        <motion.div variants={item} className="season-info mt-40 ">
          <ul className="flex flex-wrap gap-y-2 justify-center">
            <li className="peak">
              {' '}
              <span></span> Peak Season
            </li>
            <li className="mid">
              {' '}
              <span></span> Mid Season
            </li>
            <li className="rainy">
              {' '}
              <span></span> Rainy Season
            </li>
          </ul>
        </motion.div>
        {module?.description && (
          <motion.div
            variants={item}
            className="content text-center w-[850px] m-auto lgscreen:w-full lgscreen:px-30 "
          >
            {parse(module.description)}
          </motion.div>
        )}
      </motion.div>
    </section>
  );
};

export default BestTimeForGo;
export const NavigationContent_BestTimeForGoFragment = graphql`
  fragment NavigationContent_BestTimeForGoFragment on WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_BestTimeForGo {
    id
    extraClass
    hideSection
    heading
    description
    month {
      name
      season
    }
  }
`;
export const DestinationBestTimeForGoFragment = graphql`
  fragment DestinationBestTimeForGoFragment on WpDestination_Destinationcontent_DestinationContent_BestTimeForGo {
    id
    extraClass
    hideSection
    heading
    description
    month {
      name
      season
    }
  }
`;
